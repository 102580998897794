import { axios } from '@/plugins/axios';
import { KeyValue, Statement, Pagination, Resource } from '@/helpers/Interfaces';
import { FormEntry } from '@/components/forms/blueprints/form/index';
import { RelatedDocumentContract } from '@/components/forms/blueprints/logito-related-documents';

export default class BpmnService
{
    public static async simple(
        publicId: string,
        form: BpmnFormModel,
        url: string,
        buttonId: string
    ): Promise<Statement>
    {
        return (await axios.post(`simple-command/${url}/${publicId}/${buttonId}/bpmn-simple`, form)).data;
    }

    public static async owner(
        publicId: string,
        form: BpmnFormModel,
        url: string,
        buttonId: string
    ): Promise<Statement>
    {
        return (await axios.post(`simple-command/${url}/${publicId}/${buttonId}/bpmn-owner`, form)).data;
    }

    public static async return(
        publicId: string,
        form: BpmnFormModel,
        url: string,
        buttonId: string
    ): Promise<Statement>
    {
        return (await axios.post(`simple-command/${url}/${publicId}/${buttonId}/bpmn-return`, form)).data;
    }

    public static async execute(endpoint: string, form: any): Promise<Statement>
    {
        return (
            await axios.post(`simple-command/${endpoint}`, form)
        ).data;
    }

    public static async executeOld(publicId: string, form: any, licence: string, buttonId: string): Promise<Statement>
    {
        return (await axios.post(`simple-command/${licence}/${publicId}/${buttonId}/bpmn-execute`, form)).data;
    }

    public static async resume(publicId: string, form: any, url: string, buttonId: string): Promise<Statement>
    {
        return (await axios.post(`simple-command/${url}/${publicId}/bpmn-resume`, form)).data;
    }

    public static async fetchForm(
        licence: string,
        documentGuid: string,
        actionName: string,
        buttonId: string
    ): Promise<any>
    {
        return (await axios.get(`simple-schema/${licence}/${documentGuid}/${actionName}/${buttonId}`)).data;
    }

    public static async fetchFormEntry(licence: string, documentGuid: string, buttonId: string): Promise<FormEntry>
    {
        return (await axios.get(`${licence}/${documentGuid}/form-data/${buttonId}`)).data;
    }

    public static async fetchOfficeFormEntry(
        licence: string,
        documentGuid: string,
        buttonId: string
    ): Promise<FormEntry>
    {
        return (await axios.get(`${licence}/${documentGuid}/form-data/office/${buttonId}`)).data;
    }

    public static async getDepartments(): Promise<Pagination<Resource<KeyValue<string, string>>>>
    {
        return (await axios.get(`accounts/dictionaries/users/filters`)).data;
    }

    public static async getResumeFormData(licence: string, documentGuid: string): Promise<any>
    {
        return (await axios.get(`${licence}/${documentGuid}/resume-form-data`)).data;
    }

    public static async getDefaultRowsValue(licence: string, documentGuid: string, blueprintName: string, rowIndices: number[]): Promise<Record<number, any>>
    {
        return (await axios.get(`${licence}/${documentGuid}/default-row-value/${blueprintName}/${rowIndices.join(',')}`)).data;
    }
}

export interface BpmnFormModel {
    comment: string;
    attachments: string[];
}

export interface BpmnOwnerFormModel extends BpmnFormModel {
    userId: string;
}

export interface BpmnResumeFormModel extends BpmnFormModel {
    processStep: number;
    targetIds: number[];
    status: number;
}

export type DisplayMode = 'Modal' | 'Form';
export type DisplaySize = 'Small' | 'Medium' | 'Large' | 'LargeWithDocument';

export type BpmnEmitOptions = {
    id?: string;
    licence?: string;
    buttonId: string;
    actionName: string;
    displayMode?: DisplayMode;
    displaySize?: DisplaySize;
    title?: string;
    buttonName?: string;
    buttonVariant?: string;
    buttonIcon?: string;
    blueprint?: RelatedDocumentContract;
    groupAction?: boolean;
    moduleGuid?: string;
};
